import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import type { CashbackType } from 'src/types/cashbackType'

import { getCashback } from 'src/lib/api/cashback/getCashback'

type PayloadType = {
  cashbackNo?: string
}

const useGetCashbackQuery = (
  { cashbackNo }: PayloadType,
  options: UseQueryOptions<AxiosResponse<CashbackType>, AxiosError>,
) => {
  return useQuery<AxiosResponse<CashbackType>, AxiosError>(
    createKey(cashbackNo),
    () => getCashback({ cashbackNo }),
    options,
  )
}

const createKey = (cashbackNo?: string) => ['cashback', cashbackNo]

export default useGetCashbackQuery
