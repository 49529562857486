import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { cdnConfigState } from 'src/atoms/config'
import { buildImgUrl, formatPriceByLocale, getDayOfWeek } from 'src/common'

import useGetCashbackQuery from 'src/hooks/queries/cashback/useGetCashbackQuery'

import PageView from 'src/components/PageView'
import BannerSection from 'src/components/BannerSection'
import CashbackInfoSection from 'src/components/CashbackInfoSection'
import CashbackApproachSection from 'src/components/CashbackApproachSection'
import CashbackFormSection from 'src/components/CashbackFormSection'
import ReadMeSection from 'src/components/ReadMeSection/ReadMeSection'
import { format } from 'date-fns'

const CashbackFormPage = () => {
  const cdnConfig = useRecoilValue(cdnConfigState)

  const { cashbackNo } = useParams()
  const { data: cashbackData, isLoading } = useGetCashbackQuery(
    { cashbackNo },
    {
      enabled: !!cashbackNo,
      staleTime: 1000 * 60 * 6,
    },
  )

  if (!cashbackData || isLoading || !cdnConfig || !cashbackNo) return null

  const formatCashbackDate = (date: Date) => {
    const dayOfWeek = getDayOfWeek(date)
    const formatDate = format(new Date(date), 'yy.MM.dd')

    return `${formatDate} (${dayOfWeek})`
  }

  const cashbackPeriod = `${formatCashbackDate(
    cashbackData.data.start_date,
  )} - ${formatCashbackDate(cashbackData.data.end_date)}`

  const paymentPeriod = cashbackData.data.payment_end_date
    ? `${formatCashbackDate(cashbackData.data.payment_start_date)} - ${formatCashbackDate(
        cashbackData.data.payment_end_date,
      )}`
    : format(new Date(cashbackData.data.payment_start_date), 'yyyy년 M월내')
  const cashbackLimit = formatPriceByLocale('ko', cashbackData.data.cashback_limit)
  const paybackMonth = cashbackData.data.payment_end_date
    ? ''
    : format(new Date(cashbackData.data.payment_start_date), 'M')

  return (
    <PageView bgColor={cashbackData.data.bg_color}>
      <BannerSection bannerUrl={buildImgUrl(cashbackData.data.banner_img, 'banner', cdnConfig)} />
      <CashbackInfoSection
        cashbackPeriod={cashbackPeriod}
        limit={cashbackLimit}
        target={cashbackData.data.cashback_target}
        paymentPeriod={paymentPeriod}
      />
      <CashbackApproachSection
        brandLogoImg={buildImgUrl(cashbackData.data.brand_logo_img, 'brand_logo_img', cdnConfig)}
        paybackMonth={paybackMonth}
      />
      <CashbackFormSection cashbackNo={cashbackNo} modalData={cashbackData.data.modal_display} />
      <ReadMeSection />
    </PageView>
  )
}

export default CashbackFormPage
