import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

type CenterModalProps = {
  id: string
  title: string
  description: string
  onComplete?: () => void
  onCancel?: () => void
}

const CenterModal = (
  { id, title, description, onComplete, onCancel }: CenterModalProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <FullScreen id={id}>
      <WhiteBox ref={ref}>
        <Title>{title}</Title>
        <Desc>{description}</Desc>
        <ButtonBlock>
          {onCancel && <CancelButton onClick={onCancel}>취소</CancelButton>}
          {onComplete && <CompleteButton onClick={onComplete}>확인</CompleteButton>}
        </ButtonBlock>
      </WhiteBox>
    </FullScreen>
  )
}

const FullScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`

const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 27.4rem;
  border-radius: 1.8rem;
  padding-top: 2.8rem;
  padding-right: 2.3rem;
  padding-left: 2.3rem;
  padding-bottom: 1.6rem;
`

const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin-bottom: 0.8rem;
`

const Desc = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin-bottom: 1.8rem;
  color: #8d8e91;
`

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const buttonStyle = css`
  border-radius: 0.8rem;
  border: none;
  padding: 1.4rem 4.2rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;

  &:not(:nth-of-type(1)) {
    margin-left: 0.8rem;
  }
`

const CancelButton = styled.button`
  ${buttonStyle}
  background: #EEEEEE;
  color: #4e4e4e;
`

const CompleteButton = styled.button`
  ${buttonStyle}
  background: #030099;
  color: #fff;
`

export default forwardRef(CenterModal)
