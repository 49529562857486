import { ChangeEvent, useCallback, useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { CenterModalCallbackContext } from 'src/contexts/CenterModalProvider'
import useJoinCashbackMutate from 'src/hooks/queries/cashback/useJoinCashbackMutate'

import Input from './Input'
import CheckBox from './CheckBox'
import SVGLoader from '../SVGLoader'

type ModaDataType = {
  title: string
  description: string
  redirect_type: string
  redirect_dest: string
}

type CashbackFormProps = {
  cashbackNo: string
  modalData: ModaDataType
}

export type CashbackFormType = {
  accountHolderName: string
  phone: string
  accountNumber: string
  isTermsAgree: boolean
}

const ShinhanLabel = () => {
  return (
    <ShinhanLabelBlock>
      <SVGLoader name='shinhan' />
      <span>신한</span>
    </ShinhanLabelBlock>
  )
}

const CheckBoxText = () => (
  <CheckBoxTextStyle
    href='https://picturesque-avatar-ac9.notion.site/3b8704d7c37c4c0496c15c02fb6c9ebf'
    target='_blank'
  >
    이벤트 당첨에 따른 <span>개인정보 활용</span>에 동의합니다.
  </CheckBoxTextStyle>
)

const CashbackFormSection = ({ cashbackNo, modalData }: CashbackFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<CashbackFormType>({
    defaultValues: {
      accountHolderName: '',
      phone: '',
      accountNumber: '',
      isTermsAgree: false,
    },
    mode: 'onTouched',
  })

  const { mutate } = useJoinCashbackMutate()
  const { id, openCenterModal, closeCenterModal } = useContext(CenterModalCallbackContext)

  const handleCompleteModal = useCallback(() => {
    closeCenterModal(id)
    const { redirect_type: redirectType, redirect_dest: redirectDest } = modalData

    if (!redirectType && !redirectDest) return

    if (redirectType === 'path') {
      navigate(redirectDest, { replace: true })
      return
    }
    window.open(redirectDest)
  }, [closeCenterModal, id, modalData, navigate])

  const handleCancelModal = useCallback(() => {
    closeCenterModal(id)
  }, [closeCenterModal, id])

  const formatPhoneNumber = (val: string): string => {
    const regex = val.replace(/[^0-9\.]+/g, '')
    const { length } = regex

    const phonePrefix = regex.slice(0, 3)

    if (length < 4) {
      return regex
    }

    if (length <= 7) {
      return `${phonePrefix}-${regex.slice(3)}`
    }

    return `${phonePrefix}-${regex.slice(3, 7)}-${regex.slice(7, 11)}`
  }

  const handlePhoneChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setValue('phone', formatPhoneNumber(value))
    },
    [setValue],
  )

  const onSubmit: SubmitHandler<CashbackFormType> = useCallback(
    (data) => {
      mutate(
        { cashbackNo, ...data },
        {
          onSuccess: () => {
            const { title, description } = modalData
            openCenterModal(title, description, handleCompleteModal, handleCancelModal)
            reset()
          },
          onError: (err) => {
            const detailCode = err.response?.data.error.detailCode

            if (detailCode === 'INVALID_END_DATE') {
              openCenterModal(
                '이벤트 마감',
                '이벤트 기간이 종료되었습니다.',
                () => closeCenterModal(id),
                () => closeCenterModal(id),
              )
            } else if (detailCode === 'INVALID_START_DATE') {
              openCenterModal(
                '캐시백 기간이 아닙니다.',
                '기간 내에 다시 신청해주세요.',
                () => closeCenterModal(id),
                () => closeCenterModal(id),
              )
            }
          },
        },
      )
    },
    [
      mutate,
      cashbackNo,
      modalData,
      openCenterModal,
      handleCompleteModal,
      handleCancelModal,
      reset,
      closeCenterModal,
      id,
    ],
  )

  return (
    <Section>
      <WhiteBox>
        <Title>캐시백 신청</Title>
        <SubTitle>아래 정보 기입 후 캐시백 신청하기 클릭!</SubTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label='이름'
            {...register('accountHolderName', { required: true })}
            placeholder='이름 입력'
          />
          {errors.accountHolderName && errors.accountHolderName.type === 'required' && (
            <ErrorMessage>이름을 입력해주세요.</ErrorMessage>
          )}
          <Input
            type='tel'
            label='전화번호'
            {...register('phone', {
              required: true,
              pattern: /^[0-9]{3}-([0-9]{}3|[0-9]{4})-[0-9]{4}/g,
              onChange: handlePhoneChange,
              setValueAs: (val) => val.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
            })}
            placeholder='‘-’없이 전화번호 입력'
          />
          {errors.phone && errors.phone.type === 'required' && (
            <ErrorMessage>전화번호를 입력해주세요.</ErrorMessage>
          )}
          <Input
            type='text'
            inputMode='numeric'
            label={<ShinhanLabel />}
            {...register('accountNumber', {
              required: true,
              setValueAs: (val) => val.replace(/\-/g, ''),
            })}
            placeholder='‘-’없이 계좌번호 입력'
          />
          {errors.accountNumber && errors.accountNumber.type === 'required' && (
            <ErrorMessage>계좌번호를 입력해주세요.</ErrorMessage>
          )}
          <CheckBoxBlock>
            <CheckBox
              children={<CheckBoxText />}
              {...register('isTermsAgree', { required: true })}
              checked={watch('isTermsAgree')}
            />
          </CheckBoxBlock>
          <SubmitButton type='submit' disabled={!isValid}>
            캐시백 신청하기
          </SubmitButton>
        </Form>
      </WhiteBox>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.7rem;
  padding: 0 1.6rem;
  width: 100%;
`

const WhiteBox = styled.div`
  background-color: #fff;
  padding: 2.8rem 2rem;
  border-radius: 1.6rem;
  width: 100%;
`

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
  color: #030099;
  text-decoration: underline;
  text-underline-offset: 0.6rem;
  margin-bottom: 1.2rem;
`

const SubTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: #8d8e90;
  text-align: center;
  margin-bottom: 2.4rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SubmitButton = styled.button`
  border: none;
  border-radius: 0.8rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  padding: 1.7rem 0;
  width: 100%;
  background-color: #030099;
  color: #fff;

  &:disabled {
    background-color: #979797;
    color: #fff;
  }
`

const ShinhanLabelBlock = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 1.4rem;
    font-weight: 400;
    margin-left: 0.4rem;
    line-height: 1.7rem;
  }
`

const CheckBoxBlock = styled.div`
  margin-top: 2rem;
  margin-bottom: 2.4rem;
`

const CheckBoxTextStyle = styled.a`
  font-size: calc(100vw * (13 / 375));
  font-weight: 400;
  line-height: 1.7rem;
  text-decoration: none;
  color: #474747;

  > span {
    font-size: calc(100vw * (13 / 375));
    font-weight: 400;
    line-height: 1.7rem;
    text-decoration: underline;
    text-underline-position: under;
  }
`

const ErrorMessage = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  margin: 0.4rem 0;
  color: #de0000;
`

export default CashbackFormSection
