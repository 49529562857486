import React, { useRef, useState } from 'react'

export type CenterModalProps = {
  id: string
  ref: React.Ref<HTMLDivElement>
  title: string
  description: string
  onComplete: () => void
  onCancel: () => void
}

const useCenterModal = () => {
  const whiteBoxRef = useRef<HTMLDivElement>(null)
  const [openedCenterModals, setOpenedCenterModals] = useState<CenterModalProps[]>([])

  return {
    openedCenterModals,
    setOpenedCenterModals,
    whiteBoxRef,
  }
}

export default useCenterModal
