export const buildImgUrl = (filename: string, imgPath: string, cdn: string) => {
  return `${cdn}/${imgPath}/${filename}`
}

export const formatPriceByLocale = (
  local: string,
  price: number,
  options?: Intl.NumberFormatOptions,
) => {
  return new Intl.NumberFormat(local, options).format(price)
}

export const getDayOfWeek = (date: Date) => {
  const week = ['일', '월', '화', '수', '목', '금', '토']
  const dayOfWeek = week[new Date(date).getDay()]

  return dayOfWeek
}
