import styled from 'styled-components'

import SVGLoader from '../SVGLoader'

import noteImage from '../../assets/images/note.jpg'

type CashbackApproachSectionProps = {
  brandLogoImg: string
  paybackMonth: string
}

const CashbackApproachSection = ({ brandLogoImg, paybackMonth }: CashbackApproachSectionProps) => {
  return (
    <Section>
      <WhiteBox>
        <Title>신청방법</Title>
        <Flex>
          <StepBlock>
            <StepTitle>STEP. 1</StepTitle>
            <ImageBlock>
              <CircleImage src={brandLogoImg} />
            </ImageBlock>
            <StepDesc>
              캐시백 기간 중 <b>머니버스에 자산 연결한</b> 카드로 결제
            </StepDesc>
          </StepBlock>
          <ArrowIcon>
            <SVGLoader name='arrowRight' />
          </ArrowIcon>
          <StepBlock>
            <StepTitle>STEP. 2</StepTitle>
            <ImageBlock>
              <CircleImage src={noteImage} />
            </ImageBlock>
            <StepDesc>
              <b>아래 정보 기입 </b>
              {`후\n동의 및 신청`}
            </StepDesc>
          </StepBlock>
        </Flex>
        <AlarmText>캐시백은 {paybackMonth}월 중 지급됩니다.</AlarmText>
      </WhiteBox>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 0 1.6rem;
  width: 100%;
`

const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 1.6rem;
  padding: 2.8rem 2rem;
  width: 100%;
`

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
  color: #030099;
  text-decoration: underline;
  text-underline-offset: 0.6rem;
  margin-bottom: 2rem;
`

const Flex = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StepTitle = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #8d8e90;
  margin-bottom: 1.2rem;
`

const StepDesc = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
  width: calc(100vw * (144 / 375));
  white-space: pre-wrap;

  b {
    font-size: 1.4rem;
    font-weight: 600;
  }
`

const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  margin-bottom: 1.6rem;
`

const CircleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ArrowIcon = styled.div`
  position: absolute;
  top: 5.8rem;
  left: 50%;
  transform: translateX(-50%);

  > svg {
    color: #8d8e90;
  }
`

const AlarmText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: center;
  color: #030099;
  margin-top: 2.4rem;

  &::before {
    content: '※ ';
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

export default CashbackApproachSection
