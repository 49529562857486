import React from 'react'
import * as svg from './svg'

export type SVGIcontType = keyof typeof svg
export type SVGLoaderProps = {
  name: SVGIcontType
  className?: string
  style?: React.CSSProperties
}

const SVGLoader = ({ name, className, style }: SVGLoaderProps) => {
  return React.createElement(svg[name], {
    className,
    style,
  })
}

export default SVGLoader
