import styled from 'styled-components'

type PageViewProps = {
  bgColor?: string
  children: React.ReactNode
}

const PageView = ({ bgColor, children }: PageViewProps) => {
  return <Wrapper bgColor={bgColor}>{children}</Wrapper>
}

const Wrapper = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100vh;
  background-color: ${({ bgColor }) => (bgColor ? `${bgColor}` : `#ffffff`)};
  padding-bottom: 5.7rem;
  overflow-y: scroll;
`

export default PageView
