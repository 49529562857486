import client from '../client'

export type JoinCashbackPayload = {
  cashbackNo: string
  accountHolderName: string
  phone: string
  accountNumber: string
  isTermsAgree: boolean
}

export const joinCashback = async ({
  cashbackNo,
  accountHolderName,
  phone,
  accountNumber,
  isTermsAgree,
}: JoinCashbackPayload) => {
  const response = await client.post(`cashback/${cashbackNo}`, {
    account_holder_name: accountHolderName,
    phone,
    account_number: accountNumber,
    is_terms_agree: isTermsAgree,
  })

  return response.data
}
