import styled from 'styled-components'
import Badge from '../Badge'

type CashbackInfoSectionProps = {
  cashbackPeriod: string
  limit: string
  target: string
  paymentPeriod: string
}

const CashbackInfoSection = ({
  cashbackPeriod,
  limit,
  target,
  paymentPeriod,
}: CashbackInfoSectionProps) => {
  return (
    <Section>
      <InfoBlock>
        <Box>
          <BadgeBlock>
            <Badge bgColor='#474747' color='#fff'>
              사용 기간
            </Badge>
          </BadgeBlock>
          <Text>{cashbackPeriod}</Text>
        </Box>
        <Box>
          <BadgeBlock>
            <Badge bgColor='#E1E1E3'>캐시백 한도</Badge>
          </BadgeBlock>
          <Text>{limit}원</Text>
        </Box>
        <Box>
          <BadgeBlock>
            <Badge bgColor='#E1E1E3'>사용 가맹점</Badge>
          </BadgeBlock>
          <Text>{target}</Text>
        </Box>
        <Box>
          <BadgeBlock>
            <Badge bgColor='#E1E1E3'>캐시백 지급</Badge>
          </BadgeBlock>
          <Text>{paymentPeriod}</Text>
        </Box>
      </InfoBlock>
      <DescriptionBlock>
        <Description>참여 신청은 선착순으로 가능하여 조기 종료될 수 있습니다.</Description>
        <Description>
          머니버스의 가계부에서 가맹점명 확인 가능한 내역에 대해서만 캐시백 제공 받으실 수 있습니다.
        </Description>
      </DescriptionBlock>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.2rem;
  padding-bottom: 5.6rem;
  padding-right: 3rem;
  padding-left: 3rem;

  @media (max-width: 768px) {
    align-items: center;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 1.2rem;
  }
`

const BadgeBlock = styled.div`
  min-width: 9rem;
  max-width: 9rem;
  margin-right: 1.2rem;
`

const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;

  /* @media (min-width: 320px) and (max-width: 375px) {
    font-size: 1.2rem;
  } */
`

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
`

const Description = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #030099;
  padding-left: 1rem;
  text-indent: -0.5rem;
  min-width: 30.1rem;
  max-width: 30.1rem;

  &::before {
    content: '※ ';
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

export default CashbackInfoSection
