import { useMutation, UseMutationResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import { joinCashback } from 'src/lib/api/cashback/joinCashback'
import type { JoinCashbackPayload } from 'src/lib/api/cashback/joinCashback'
import type { IErrorResponse } from 'src/types/IErrorRespose'

const useJoinCashbackMutate = (): UseMutationResult<
  {},
  AxiosError<IErrorResponse>,
  JoinCashbackPayload
> => {
  return useMutation((payload: JoinCashbackPayload) => joinCashback(payload))
}

export default useJoinCashbackMutate
