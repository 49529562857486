import React, { forwardRef, memo } from 'react'
import styled, { css } from 'styled-components'

import SVGLoader from '../SVGLoader'

type CheckBoxProps = {
  type?: string
  children: React.ReactNode
  checked: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const CheckBox = (
  { type = 'checkbox', children, checked, ...rest }: CheckBoxProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  return (
    <Block>
      <Label htmlFor={rest.id}>
        <HideInput ref={ref} type={type} id={rest.id} {...rest} />
        <CheckBoxStyle checked={checked}>
          <SVGLoader name='check' />
        </CheckBoxStyle>
        {children && children}
      </Label>
    </Block>
  )
}

const Block = styled.div``

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
`

const HideInput = styled.input`
  position: absolute;
  display: none;
`

const CheckBoxStyle = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: #eeeeee;
  margin-right: 0.8rem;

  ${({ checked }) =>
    checked &&
    css`
      background-color: #4e4e4e;
    `}
`

export default memo(forwardRef<HTMLInputElement, CheckBoxProps>(CheckBox))
