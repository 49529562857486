import client from '../client'

type GetCashbackPayload = {
  cashbackNo?: string
}

export const getCashback = async ({ cashbackNo }: GetCashbackPayload) => {
  const response = await client.get(`cashback/${cashbackNo}`)

  return response.data
}
