import { RefObject, createContext, createRef, useCallback, useMemo, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'

import useCenterModal, { CenterModalProps } from 'src/hooks/useCenterModal'

import CenterModal from 'src/components/CenterModal'

type CenterModalProviderProps = {
  children: React.ReactNode
}

type CenterModalCallbackType = {
  ref: RefObject<HTMLElement>
  id: string
  openCenterModal: (
    title: string,
    description: string,
    onComplete: () => void,
    onCancle: () => void,
  ) => void
  closeCenterModal: (id: string) => void
}

export const CenterModalStateContext = createContext([] as CenterModalProps[])

export const CenterModalCallbackContext = createContext<CenterModalCallbackType>({
  id: '',
  openCenterModal: () => {},
  closeCenterModal: () => {},
  ref: createRef(),
})

const CenterModalProvider = ({ children }: CenterModalProviderProps) => {
  const centerModalId: string = uniqueId('center-modal-')
  const { openedCenterModals, setOpenedCenterModals, whiteBoxRef } = useCenterModal()

  const openCenterModal = useCallback(
    (title: string, description: string, onComplete: () => void, onCancel: () => void) => {
      setOpenedCenterModals((modals) => [
        ...modals,
        {
          id: centerModalId,
          ref: whiteBoxRef,
          title,
          description,
          onComplete,
          onCancel,
        },
      ])
    },
    [centerModalId, setOpenedCenterModals, whiteBoxRef],
  )

  const closeCenterModal = useCallback(
    (currId: string) => {
      setOpenedCenterModals((modals) => modals.filter(({ id }) => id !== currId))
    },
    [setOpenedCenterModals],
  )

  const callbackContextValue = useMemo(
    () => ({ id: centerModalId, openCenterModal, closeCenterModal, ref: whiteBoxRef }),
    [centerModalId, openCenterModal, closeCenterModal, whiteBoxRef],
  )

  useEffect(() => {
    const handleOutSideClick = (e: MouseEvent) => {
      const event = e.target as HTMLElement

      if (whiteBoxRef.current && !whiteBoxRef.current.contains(event)) {
        const { id } = event
        closeCenterModal(id)
      }
    }
    if (openedCenterModals.length > 0) {
      document.addEventListener('mousedown', handleOutSideClick)
    }
    return () => document.addEventListener('mousedown', handleOutSideClick)
  }, [closeCenterModal, openedCenterModals, whiteBoxRef])

  return (
    <CenterModalCallbackContext.Provider value={callbackContextValue}>
      <CenterModalStateContext.Provider value={openedCenterModals}>
        {children}
        {openedCenterModals.map(({ id, ref, title, description, onCancel, onComplete }) => (
          <CenterModal
            key={id}
            id={id}
            ref={ref}
            title={title}
            description={description}
            onCancel={onCancel}
            onComplete={onComplete}
          />
        ))}
      </CenterModalStateContext.Provider>
    </CenterModalCallbackContext.Provider>
  )
}

export default CenterModalProvider
