import React, { memo, forwardRef } from 'react'
import styled from 'styled-components'

type InputProps = {
  label: string | React.ReactNode
  placeholder: string
} & React.InputHTMLAttributes<HTMLInputElement>

const Input = ({ label, placeholder, ...rest }: InputProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <InputBlock>
      <Label>{label}</Label>
      <InputStyle ref={ref} placeholder={placeholder} {...rest} />
    </InputBlock>
  )
}

const InputBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #dee0e7;
  border-radius: 0.8rem;

  & + & {
    margin-top: 1.2rem;
  }
`

const Label = styled.label`
  flex: 0 1 auto;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  padding-left: 1rem;
  color: #8d8e90;
  min-width: 6rem;
`

const InputStyle = styled.input`
  flex: 1 1 auto;
  width: 100%;
  padding: 1.3rem 1rem;
  outline: none;
  border: none;
  font-size: 1.4rem;

  &::placeholder {
    color: #bdbfc5;
  }
`

export default memo(forwardRef<HTMLInputElement, InputProps>(Input))
