import React from 'react'
import { Route, Routes } from 'react-router-dom'

import GlobalStyle from './styles/GlobalStyle'

import CashbackFormPage from './pages/CashbackFormPage'

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path='/event/:cashbackNo' element={<CashbackFormPage />} />
      </Routes>
    </>
  )
}

export default App
