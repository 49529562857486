import styled from 'styled-components'

type BannerSectionProps = {
  bannerUrl: string
}

const BannerSection = ({ bannerUrl }: BannerSectionProps) => {
  return (
    <Section>
      <Image src={bannerUrl} width='100%' height='100%' />
    </Section>
  )
}

const Section = styled.div`
  width: 100%;
`

const Image = styled.img`
  object-fit: contain;
`

export default BannerSection
