import styled from 'styled-components'
import SVGLoader from '../SVGLoader'

const ReadMeSection = () => {
  return (
    <Section>
      <Title>
        <SVGLoader name='warn' />꼭 읽어주세요!
      </Title>
      <TextList>
        <TextItem>
          신한은행 x 비주얼 리워드는 머니버스 사용자를 위해 캐시백 쿠폰을 드리는 이벤트입니다.
        </TextItem>
        <TextItem>이벤트 참여를 위해 머니버스에 사용하시는 카드를 꼭 자산연결 해주세요.</TextItem>
        <TextItem>
          캐시백 쿠폰 다운로드 후, 자산연결한 카드로 사용 기간 내 결제하면, 자동으로 3월내로
          캐시백이 제공 됩니다.
        </TextItem>
        <TextItem>캐시백은 입력하신 계좌로 지급되니 정확한 입력을 부탁드립니다.</TextItem>
        <TextItem>캐시백 쿠폰은 1,000명 선착순으로 지급되어 조기 마감될 수 있습니다.</TextItem>
        <TextItem>
          중요 : <b>머니버스의 가계부에서 가맹점명 확인 가능한</b> 내역만 캐시백 제공 받으실 수
          있습니다. 간편페이 등으로 결제하여 머니버스 가계부에서 결제 내역 확인이 어려울 경우 캐시백
          제공이 제한되는 점 양해부탁드립니다.
        </TextItem>
        <TextItem>문의사항은 cs@tenqube.com로 보내주시면 순차적으로 답변 드리겠습니다.</TextItem>
      </TextList>
    </Section>
  )
}

const Section = styled.div`
  padding: 0 1.6rem;
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.7rem;
  margin-bottom: 1.2rem;

  color: #8d8e90;
  svg {
    margin-right: 0.5rem;
  }
`

const TextList = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  color: #8d8e90;
  padding-left: 1.7rem;
`

const TextItem = styled.li`
  display: list-item;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1rem;
  b {
    font-size: 1.3rem;
    font-weight: 600;
    color: #757678;
  }
`

export default ReadMeSection
