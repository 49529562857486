import axios from 'axios'
import { REACT_APP_API_END_POINT } from 'src/common/config'

const client = axios.create({
  withCredentials: true,
})

client.defaults.baseURL = REACT_APP_API_END_POINT

export default client
