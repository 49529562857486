import styled, { css } from 'styled-components'

type BadgeProps = {
  color?: string
  bgColor?: string
  size?: number
  m?: string
  children: React.ReactNode
}

const Badge = ({ color, bgColor, size = 14, m, children }: BadgeProps) => {
  return (
    <BadgeStyle color={color} bgColor={bgColor} size={size} m={m}>
      {children}
    </BadgeStyle>
  )
}

const BadgeStyle = styled.div<{ bgColor?: string; size: number; m?: string }>`
  display: inline-block;
  text-align: center;
  padding: 0.6rem 1.3rem;
  border-radius: 5rem;
  font-size: ${({ size }) => size / 10}rem;
  font-weight: 600;
  width: auto;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ bgColor }) =>
    bgColor &&
    css`
      background: ${bgColor};
    `};

  ${({ m }) =>
    m &&
    css`
      margin: ${m};
    `}
`

export default Badge
